.client-icon-container {
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
.client-icon {
  width: 15vw;
  height: 10vw;
  max-width: 200px;
  min-width: 100px;
}

@media screen and (max-width: 400px) {
  .Clients {
    background-color: #eaf2f6;
    padding: 1rem;
  }
  .client-icon-container {
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 2rem 0.5rem;
  }
}
