.Clients {
  background-color: #eaf2f6;
  padding: 1rem 4rem;
}

.clients-outer-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.clients-outer-div .clients-inner-div {
  flex: 1;
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 400px) {
  .Clients {
    background-color: #eaf2f6;
    padding: 1rem;
  }
}
