.details-text {
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 24px;
  font-family: Roboto;
  font-weight: 300;
  font-size: 20px;
  width: 1068px;
  height: auto;
  text-align: left;
  cursor: default;
}
.subtitle-text {
  color: gray;
}
.section-title-text {
  font-size: 22px;
}
@media screen and (max-width: 768px) {
  .details-text {
    width: 50%;
  }
}
