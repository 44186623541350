.apply-form-section {
  background-color: #f7f9fa;
}
.apply-form {
  display: flex;
  flex-direction: column;
  padding: 0% 10% 5% 10%;
}
.apply-form-divider {
  border-top: 1px solid #d9d9d9;
  flex: 1;
  margin: 20px 15px 0px;
}
.apply-form label,
.apply-form input,
.apply-form button {
  margin-inline: 10px;
}
.submit-btn {
  align-self: flex-end;
  padding-inline: 60px;
}
.form-2-cols {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.form-3-cols {
  display: grid;
  grid-template-columns: 4fr 3fr 1fr;
}
.form-4-cols {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.form-5-cols {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 1fr 1fr;
}
.form-row-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 49px;
  align-self: flex-start;
  margin: 15px 10px 0px;
  color: var(--primary-blue);
}
@media screen and (max-width: 700px) {
  .form-2-cols {
    display: grid;
    grid-template-columns: 1fr;
  }
  .form-3-cols {
    display: grid;
    grid-template-columns: 1fr;
  }
  .form-4-cols {
    display: grid;
    grid-template-columns: 1fr;
  }
  .form-5-cols {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .apply-form .submit-btn {
    width: -webkit-fill-available;
    margin: 30px;
  }
}
