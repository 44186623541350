.address-container {
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 28px;
  color: var(--white);
  padding: 0 10%;
}
.address-container * {
  color: rgb(191, 186, 186);
}
.copyrights {
  padding: 3%;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.copyrights p {
  color: rgb(191, 186, 186);
}
.cont {
  display: flex;
  padding: 3% 0 5% 0;
}
.inner-cont {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: start;
}
.icon-container {
  margin: 0px 20px 0px 0px;
  height: fit-content;
}
.inner-cont-title {
  font-weight: 400;
  font-size: 1.125rem;
}
.inner-cont-details-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  opacity: 0.5;
}
.inner-cont-details {
  display: flex;
}
.inner-cont-details-title {
  flex: 1;
}
.inner-cont-details-value {
  flex: 3;
}
@media screen and (max-width: 992px) {
  .icon-container {
    margin: 20px 20px 0px 0px;
    height: fit-content;
  }
  .cont {
    display: flex;
    padding: 2% 0 4% 0;
  }
}
