.form-field {
  text-align: start;
  margin: 10px;
  margin-bottom: 20px;
  display: grid;
  position: relative;
}
.form-field-placeholder {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #787878;
}
.form-field-error-message {
  top: 100%;
  left: 0%;
  right: -50%;
  position: absolute;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: var(--error);
  width: fit-content;
}
.form-field .text-input,
.form-field .text-area,
.form-field .dropdown {
  align-self: flex-end;
  margin: 0px;
  width: 100%;
  margin-top: 5px;
}
.form-field .input-number {
  padding-inline: 20px;
  align-self: "flex-end";
}
