.how-we-grid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.how-we-grid-icon {
  width: 130px;
  height: 114px;
}
.how-we-grid-item {
  margin-bottom: 30px;
}

.how-we-grid-item-title {
  font-family: Roboto;
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: center;
  color: #3b3a3b;
  cursor: default;
  margin-top: 2vh;
  margin-top: 20px;
}

.how-we-grid-item-description {
  width: 368px;
  font-family: Roboto;
  width: 80%;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  color: #787878;
  cursor: default;
  margin-top: 2vh;
}

.how-we-grid-item-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .how-we-grid-item {
    margin-bottom: 10%;
  }

  .how-we-grid-item-title {
    text-align: center;
  }
  .how-we-grid-item-description {
    text-align: center;
    width: 96%;
  }
}
