.about-us-intro-section {
  background-color: var(--white);
  background: url("../../../../assets/bg_icon_2.svg") no-repeat left;
  background-size: auto 80%;
  padding: 0% 10% 5% 10%;
}
.about-us-intro-description {
  text-align: start;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  color: var(--font-secondary);
}
