.horizontal-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .horizontal-list {
    overflow-x: scroll;
    white-space: nowrap;
    width: 88%;
    margin-left: 2%;
  }
}

.close-details {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  z-index: 901 !important;
}
@media screen and (max-width: 768px) {
  .close-details {
    top: 3rem;
  }
}

.arrow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.details-list-item {
  cursor: pointer;
  width: 120px !important;
}

.details-icon-title {
  width: 320px;
  margin-left: -115px;
}

.item-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
}

@media screen {
  .item-text {
    padding: 20px;
  }
}
