.button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: normal;
  padding: 15px 30px;
  border-radius: 50px;
  box-sizing: border-box;
  transition: 0.1s;
  margin: 15px;
  color: var(--white);
  background-color: var(--primary-blue);
  border: none;
}
.light-theme-btn {
  color: var(--white);
  background-color: var(--primary-blue);
  transition: 0.1s;
}
.light-theme-btn:hover {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
}
.light-theme-btn:active {
  color: var(--light-grey);
  background-color: #659ebd;
}
.light-theme-btn:disabled {
  color: var(--font-secondary);
  background-color: var(--icon-disabled);
}
.dark-theme-btn {
  color: var(--font-primary);
  background-color: #91c8e6;
  transition: 0.1s;
}
.dark-theme-btn:hover {
  box-shadow: 0px 2px 5px rgba(255, 255, 255, 0.15);
}
.dark-theme-btn:active {
  color: var(--black);
  background-color: #92cded;
}
.dark-theme-btn:disabled {
  color: var(--light-grey);
  background-color: #494949;
}

.light-outline-btn {
  color: var(--med-grey);
  background-color: transparent;
  border: 2px solid var(--med-grey);
  transition: 0.1s;
}
.light-outline-btn:hover {
  background-color: rgba(120, 120, 120, 0.1);
}
.light-outline-btn:active {
  color: var(--dark-grey);
  background-color: rgba(120, 120, 120, 0.15);
}
.light-outline-btn:disabled {
  color: var(--light-grey);
  border: 2px solid var(--light-grey);
}

.dark-outline-btn {
  color: var(--white);
  background-color: transparent;
  border: 2px solid var(--white);
  transition: 0.1s;
}
.dark-outline-btn:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.dark-outline-btn:active {
  background-color: rgba(255, 255, 255, 0.25);
}
.dark-outline-btn:disabled {
  color: var(--med-grey);
  border: 2px solid var(--med-grey);
}
@media screen and (max-width: 700px) {
  .button {
    font-size: 18px;
    margin: 10px;
  }
}
