.autocomplete-input {
  position: relative;
}
.autocomplete-input .options-list {
  position: absolute;
  max-height: fit-content;
  overflow: scroll;
  overflow-x: hidden;
  background-color: var(--white);
  top: calc(100% + 10px);
  left: 0%;
  right: 0%;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--light-grey);
  border-radius: 8px;
  font-size: 16px;
  padding: 10px 0;
  color: var(--font-secondary);
  z-index: 1000;
}
.autocomplete-input .options-list .option {
  width: -webkit-fill-available;
  outline: unset;
  padding: 10px 20px;
  cursor: pointer;
}
.autocomplete-input .options-list .option:hover,
.autocomplete-input .options-list .option:focus {
  background-color: rgba(225, 236, 242, 0.75);
}
.autocomplete-input .options-list .option:active {
  background-color: #ccdce5;
}
.no-suggestion {
  display: block;
  padding: 10px 20px;
}
