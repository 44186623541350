.intro-main-container {
  padding: 0;
  margin: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 60px;
  line-height: 64px;
  text-align: center;
  color: rgba(255, 255, 255, 0.88);
}
.our-work-btn {
  background-color: transparent;
  border: 2px solid var(--white);
  color: var(--white);
  padding-inline: 58px;
  box-sizing: border-box;
}
.scroll-down-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--white);
  padding: 2.5%;
  animation: cssAnimation 1s ease-in 2s forwards, MoveUpDown 2s linear infinite;
  position: relative;
  visibility: hidden;
  text-decoration: unset;
}
.scroll-down-btn:hover {
  background-color: unset;
  color: var(--white);
}
.scroll-down-btn-text {
  padding: 8px;
}
.slide1 {
  background: url("../../../../assets/placeholder.svg") no-repeat left,
    linear-gradient(0deg, #121e24d9, #121e24d9),
    url("../../../../assets/image_s1_jpg.jpg") no-repeat center;
  height: 100vh;
  width: 100vw;
  position: relative;
  background-size: auto 100%, cover, cover;
}
.slide2 {
  background: url("../../../../assets/placeholder.svg") no-repeat left,
    linear-gradient(0deg, #121e24d9, #121e24d9),
    url("../../../../assets/image_s2_jpg.jpg") no-repeat center;
  height: 100vh;
  width: 100vw;
  position: relative;
  background-size: auto 100%, cover, cover;
}
.intro-description {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  opacity: 0;
  transform: translateY(-20px);
  overflow: hidden;
}
.slide-buttons {
  position: absolute;
  bottom: 0%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.carousel-inner .carousel-item {
  transition: 1s ease-in-out;
}
.carousel-item.active .intro-description {
  transform: translateY(0px);
  opacity: 1;
}
.carousel-item-start .intro-description {
  transform: translateY(-20px) !important;
  opacity: 0 !important;
}
.decription-label {
  position: relative;
  padding: 0% 15%;
  width: 100%;
}
.next-btn-label {
  position: absolute;
  top: 0%;
  right: 0%;
  opacity: 0.45;
  text-align: start;
  background-color: transparent;
  border: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 60px;
  line-height: 64px;
  text-align: center;
  color: rgba(255, 255, 255, 0.45);
}
@media screen and (max-width: 800px) {
  .intro-main-container,
  .next-btn-label {
    font-size: 28px;
    line-height: 36px;
  }
  .scroll-down-btn {
    padding: 2%;
  }
  .our-work-btn {
    padding: 15px 55px;
    margin-bottom: 5%;
  }
  .slide-buttons {
    bottom: 2%;
  }
}
@media screen and (max-height: 500px) {
  .intro-main-container,
  .next-btn-label {
    font-size: 28px;
    line-height: 36px;
  }
  .scroll-down-btn {
    padding: 0%;
  }
  .our-work-btn {
    padding: 10px 30px;
    margin-bottom: 1%;
  }
  .slide-buttons {
    bottom: 0.5%;
  }
}
@media screen and (orientation: landscape) and (max-width: 900px) and (max-height: 500px) {
  .intro-main-container,
  .next-btn-label {
    font-size: 28px;
    line-height: 36px;
  }
  .scroll-down-btn {
    padding: 2%;
  }
  .our-work-btn {
    padding: 10px 30px;
    margin-bottom: 1%;
  }
  .slide-buttons {
    bottom: 0%;
  }
}
@keyframes MoveUpDown {
  0%,
  100% {
    bottom: 0px;
  }

  50% {
    bottom: 20px;
  }
}
@keyframes cssAnimation {
  100% {
    visibility: visible;
  }
}
