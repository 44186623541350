.navbar-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  position: relative;
  z-index: 10000;
  width: 100%;
  padding: 3vh 4vw;
  width: 100%;
  padding: 2vh 4vw;
}
.navbar-container.fixed {
  position: fixed;
  color: rgba(255, 255, 255, 0.68);
  background-color: transparent;
}
.navbar-container.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  color: var(--font-secondary);
  background-color: var(--light-blue);
}
.nav-links-container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
}
.collapse-btn {
  display: none;
}
.navbar-link,
.navbar-link:hover,
.navbar-link:active,
.navbar-link:focus {
  margin-left: 50px;
  color: rgba(255, 255, 255, 0.68);
  text-decoration: none;
}
.navbar-container.sticky .navbar-link {
  color: var(--font-secondary);
}
.navbar-container.sticky .navbar-link.active {
  color: var(--primary-blue);
  border-bottom: 2px solid var(--primary-blue);
}
@media screen and (max-width: 992px) {
  .collapse-btn {
    display: block;
    z-index: 100;
  }
  .navbar-container.sticky .navbar-link,
  .navbar-container.fixed .navbar-link {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: normal;
    text-align: center;
    color: #ffffff80;
    margin: 15px 0px;
  }
  .navbar-container.sticky .navbar-link.active,
  .navbar-container.fixed .navbar-link.active {
    color: var(--white);
  }
  .nav-links-container {
    position: absolute;
    flex: none;
    flex-direction: column;
    border-bottom-left-radius: 32px;
    background-color: var(--primary-blue);
    top: 0%;
    left: 100%;
    right: 0%;
    max-height: 0px;
    overflow: hidden;
    padding-top: 30px;
    padding-bottom: 5px;
    transition: left 0.3s ease-in-out, max-height 0.2s ease-in-out;
    transition: left 0.4s ease-in-out, max-height 0.4s ease-in-out;
  }
  .nav-links-container.show {
    max-height: 500px;
    left: 0%;
  }
}
