.get-in-touch-section-container {
  background-color: #192a32;
}
.get-in-touch-main-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 10% 5% 10%;
}
.get-in-touch-form {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.get-in-touch-form .send-btn {
  width: max-content;
  padding-inline: 60px;
  align-self: flex-end;
}
.form-row {
  display: flex;
}
.get-in-touch-form .form-field {
  display: flex;
  flex-direction: row;
  flex: 1;
}
.get-in-touch-form .form-field .placeholder {
  display: none;
}
.form-row .text-input {
  flex: 1;
}
.form-last-row {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}
.toast-style {
  width: 450px;
  text-align: start;
  right: 140px;
}
.popup-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}
.privacy-policy-field {
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
}
.privacy-policy-field * {
  font-size: 20px;
  color: rgb(191, 186, 186);
}
.privacy-policy-text {
  cursor: pointer;
  text-decoration: none;
  color: #91c8e6;
}
.checkbox-input {
  width: 25px;
  height: 25px;
  border-radius: 25%;
  vertical-align: middle;
  border: 1px solid black;
  appearance: none;
  background-color: white;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}
.checkbox-input-error {
  border: 1.5px solid var(--error);
}
.checkbox-input:checked {
  background-color: #91c8e6;
  border-color: #91c8e6;
}
.checkbox-input:checked::after {
  content: "✔"; /* Unicode for check mark */
  color: white; /* Color of the check mark */
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.agreement-submit-section {
  display: flex;
}
@media screen and (max-width: 700px) {
  .form-row {
    flex-direction: column;
  }
  .form-last-row {
    flex-direction: column-reverse;
  }
  .get-in-touch-form .send-btn {
    width: -webkit-fill-available;
  }
  .get-in-touch-form .text-area {
    height: 332px;
  }
  .message-container {
    width: -webkit-fill-available;
    font-size: 16px;
    margin: 10px;
  }
  .toast-style {
    width: auto;
    text-align: center;
    right: 0px;
  }
  .agreement-submit-section {
    flex-direction: column;
  }
}
