.page-navbar-container {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-inline: 5%;
  background-color: var(--white);
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: var(--font-secondary);
  position: fixed;
  width: 100%;
  z-index: 100;
}
.page-navbar-next-icon {
  margin: 0 8px;
}
.page-navbar-container .active-link {
  color: var(--primary-blue);
  font-weight: 700;
}
