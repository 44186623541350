.privacy-container {
  display: flex;
  flex-direction: column;
  justify-items: center;
  text-align: start;
  padding: 10px 100px 60px 100px;
}
.privacy-container > * {
  margin: 10px 0 10px 0;
}
.privacy-title {
  text-align: center;
}
.policy-title {
  font-size: 25px;
  font-weight: bold;
}
.point {
  padding: 0 10px 0 10px;
  align-items: center;
}
.point * {
  margin: auto 10px auto 0;
}
.point-title {
  font-weight: bold !important;
  font-size: 17px;
}
.privacy-title p {
  display: inline-block;
  position: relative;
  font-size: 50px;
}

.privacy-title p:after {
  content: "";
  height: 1px;
  width: 10%;
  background-color: #609bb9;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translate(-50%);
}
.privacy-email-anchor {
  color: black;
  text-decoration: none;
  border-bottom: 1px solid black;
}
.privacy-email-anchor:hover {
  color: var(--bs-link-color);
  text-decoration: none;
  border-bottom: 1px solid var(--bs-link-color);
}
.gray-text {
  color: #565656;
}
.black-text {
  color: black;
}
@media screen and (max-width: 800px) {
  .privacy-container {
    display: flex;
    flex-direction: column;
    justify-items: center;
    text-align: start;
    padding: 0 30px 0 30px;
  }
  .privacy-title p:after {
    width: 20%;
  }
  .privacy-title p {
    font-size: 40px;
  }
}
@media screen and (max-width: 500px) {
  .privacy-title p {
    font-size: 30px;
  }
  .privacy-title p:after {
    width: 30%;
  }
}
