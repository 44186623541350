.how-we-icons-grid {
  background-color: #f7f9fa;
  background-image: url("../../../../assets/HowWeDoIt/pattern_about.svg");
  background-repeat: no-repeat;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .how-we-icons-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }
}

.how-we-icons {
  margin-top: 20px;
  padding: 0px 25%;
}

@media screen and (max-width: 768px) {
  .how-we-icons {
    margin-top: 15%;
    padding: 0px 50%;
  }
}

.our-work-icon-div {
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .our-work-icon-div {
    width: 100% !important;
    height: auto;
  }
}
