.line {
  width: 100px;
  height: 2px;
  background-color: #609bb9;
}
.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 60px;
  line-height: 64px;
  color: #609bb9;
  margin: 10px;
}

@media screen and (max-width: 768px) {
  .title {
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
  }
}

.title-container {
  padding: 3% 0%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 700px) {
  .title {
    font-size: 28px;
    line-height: 36px;
  }
}
