.success-window {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10000;
  opacity: 1;
  overflow: hidden;
}
.circle-loader {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left-color: var(--primary-blue);
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: 80px;
  height: 80px;
}

.load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: var(--primary-blue);
  transition: border 500ms ease-out;
  background-color: var(--primary-blue);
  transition: background-color 250ms ease-out;
}

.checkmark {
  display: none;
}
.checkmark.draw::after {
  animation-duration: 800ms;
  animation-timing-function: ease;
  animation-name: checkmark;
  transform: scaleX(-1) rotate(135deg);
}
.checkmark::after {
  opacity: 1;
  height: 40px;
  width: 20px;
  transform-origin: left top;
  border-right: 3px solid var(--white);
  border-top: 3px solid var(--white);
  content: "";
  left: calc(80px / 6 + 80px / 12);
  top: 40px;
  position: absolute;
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 20px;
    opacity: 1;
  }
  40% {
    height: 40px;
    width: 20px;
    opacity: 1;
  }
  100% {
    height: 40px;
    width: 20px;
    opacity: 1;
  }
}
