.job-details-section {
  background-color: #f7f9fa;
  padding: 0% 10% 5% 10%;
  display: flex;
  flex-direction: column;
  text-align: start;
}
.job-details-section .apply-now-btn {
  width: 100%;
  max-width: 400px;
  text-transform: capitalize;
  align-self: center;
  margin: 40px 0 0 0;
}
.job-description {
  color: var(--font-primary);
}
@media screen and (max-width: 700px) {
  .job-details-section .apply-now-btn {
    margin: 40px 0 30px 0;
  }
}
