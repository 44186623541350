.light-theme {
  color: var(--font-secondary);
  border: 1px solid var(--light-grey);
  background-color: var(--white);
  transition: 0.1s;
}
.light-upload-placeholder,
.light-theme::placeholder {
  color: #78787880;
}
.light-theme:disabled {
  background-color: var(--light-grey);
  border: none;
}
.dark-theme {
  color: #ffffff;
  border: 1px solid rgba(217, 217, 217, 0.05);
  background-color: rgba(255, 255, 255, 0.1);
  transition: 0.1s;
}
.dark-upload-placeholder,
.dark-theme::placeholder {
  color: #ffffff80;
}
.dark-theme:disabled {
  color: #ffffff33;
  border: none;
  background-color: rgba(255, 255, 255, 0.05);
}
.text-input {
  display: flex;
  align-items: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: normal;
  outline: unset;
  padding: 15px 30px;
  border-radius: 50px;
  box-sizing: border-box;
  transition: 0.1s;
  margin: 15px;
  min-width: 50px;
}
.text-input:hover {
  border: 1px solid var(--primary-blue);
}
.text-input:focus {
  border: 1px solid var(--med-blue);
}
.input-file {
  justify-content: space-between;
}
.upload-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-input.error {
  border: 1px solid var(--error);
}
@media screen and (max-width: 700px) {
  .text-input {
    font-size: 18px;
    margin: 10px;
  }
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.error-icon {
  position: absolute;
  right: 10px;
  color: #ff6b6b;
  font-size: 20px;
  margin: 5px;
}
