.light-theme {
  color: var(--font-secondary);
  border: 1px solid var(--light-grey);
  background-color: var(--white);
  transition: 0.1s;
}
.light-theme::placeholder {
  color: #78787880;
}
.light-theme:disabled {
  background-color: var(--light-grey);
  border: none;
}
.dark-theme {
  color: #ffffff;
  border: 1px solid rgba(217, 217, 217, 0.05);
  background-color: rgba(255, 255, 255, 0.1);
  transition: 0.1s;
}
.dark-theme::placeholder {
  color: #ffffff80;
}
.dark-theme:disabled {
  color: #ffffff33;
  border: none;
  background-color: rgba(255, 255, 255, 0.05);
}
.text-area {
  display: flex;
  align-items: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  outline: unset;
  padding: 20px 30px;
  border-radius: 32px;
  box-sizing: border-box;
  transition: 0.1s;
  margin: 15px;
  height: 165px;
  resize: none;
}
.text-area:hover {
  border: 1px solid var(--primary-blue);
}
.text-area:focus {
  border: 1px solid var(--med-blue);
}
.text-area.error {
  border: 1px solid var(--error);
}
@media screen and (max-width: 700px) {
  .text-area {
    font-size: 18px;
    line-height: 22px;
    margin: 10px;
  }
}
