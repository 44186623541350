.domains {
  display: flex;
  align-items: center;
  padding: 80px;
  background: #f7f9fa;
  background-image: url("../../../../assets/Domains/domains_background_arrow.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  justify-content: center;
  color: black;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .domains {
    padding: 40px;
  }
}

@media screen and (max-width: 768px) {
  .domains {
    height: auto;
    padding: 67px 10px 10px 10px;
  }
}

.bg_arrow {
  position: absolute;
  right: 0;
  z-index: 1;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
