.master-icon-img {
  width: 128px !important;
  height: 128px !important;
  margin-bottom: 20px;
}

.master-icon-title {
  font-size: 26px;
  font-weight: 700;
  color: #5a5a5a !important;
  font-family: Roboto !important;
  cursor: default;
}

.master-icon-title p {
  margin-bottom: 20px;
}
.master-icon-description {
  font-size: 16px;
  color: #4b4b4b;
  padding: 0 20px;
  font-family: Roboto;
  font-weight: 300;
  cursor: default;
}
@media screen and (max-width: 768px) {
  .master-icon {
    margin-bottom: 10%;
  }
}

@media screen and (max-width: 768px) {
  .master-icon-description {
    width: auto;
  }
}
