body {
  margin: 0;
  font-family: "Roboto";
}

:root {
  --primary-blue: #6096b4;
  --light-blue: #e1ecf2;
  --med-blue: #7ca5c0;
  --dark-blue: #466173;
  --white: #ffffff;
  --black: #000000;
  --light-grey: #d9d9d9;
  --med-grey: #787878;
  --dark-grey: #3b3a3b;
  --font-primary: #3b3a3b;
  --font-secondary: #787878;
  --font-disabled: #d9d9d9;
  --icon-primary: #3b3a3b;
  --icon-secondary: #787878;
  --icon-disabled: #d9d9d9;
  --success: #00a300;
  --warning: #e96500;
  --error: #d62929;
}

#root {
  white-space: pre-line;
}
