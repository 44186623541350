.why-work-with-us-item {
  margin-bottom: 60px;
}

@media screen and (max-width: 768px) {
  .why-work-with-us-item {
    margin-bottom: 20px;
  }
}

.why-work-with-us-item-title {
  font-family: Roboto;
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
  color: #3b3a3b;
  padding: 0px 168px;
  cursor: default;
}
.why-work-with-us-item-text {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #787878;
  cursor: default;
}
