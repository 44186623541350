.job-item {
  margin: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 50px;
  background-color: var(--light-blue);
  color: var(--primary-blue);
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
  padding: 20px 30px;
  cursor: pointer;
  transition: 0.1s;
  border: none;
  text-align: start;
}
.job-item:hover {
  background-color: rgba(225, 236, 242, 0.75);
}
.job-item:active {
  background-color: #ccdce5;
}
@media screen and (max-width: 700px) {
  .job-item {
    padding: 15px 30px;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
  }
}
