.popup-window {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10000000;
  max-height: 0px;
  opacity: 0;
  overflow: hidden;
  transition: opacity 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.popup-content {
  background: var(--white);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 66px 53px;
  gap: 10px;
  width: 460px;
}
.popup-window.show {
  max-height: 100%;
  opacity: 1;
}
.popup-window .title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 49px;
  text-align: center;
}
.popup-window .message {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  color: var(--font-primary);
  padding: 0px 0 30px;
}
.popup-window .button {
  width: -webkit-fill-available;
  margin-inline: 30px;
}
.popup-window .title.success {
  color: var(--primary-blue);
}
.popup-window .title.error {
  color: var(--error);
}
.popup-window .title.warning {
  color: var(--warning);
}
