.detail-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  border-radius: 20px;
}

@media screen and (max-width: 768px) {
  .detail-icon img {
    height: 100px;
    width: 100px;
  }
}

.details-icon-img {
  width: 100px;
  height: 78px;
  border-radius: 10px;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .details-icon-img {
    width: 100px;
    height: 100px;
  }
}

.details-icon-hover {
  opacity: 1;
  cursor: pointer;
  background-color: #e1ecf2;
  border-radius: 15px;
  scroll-margin-top: 1000px;
}

.details-icon-title h2 {
  color: #333;
  font-size: 1.2rem;
  font-weight: 400;
  width: 320px;
}

.details-icon-title p {
  color: #999;
  font-size: 0.7rem;
  font-weight: 400;
  width: 320px;
}
