.why-work-this-us {
  background: #eaf2f6;
  background-image: url("../../../../assets/HowWeDoIt/pattern_our_work.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  height: auto;
  overflow: hidden;
}

.why-work-with-us-col {
  padding: 0px 186px;
}

@media screen and (max-width: 768px) {
  .why-work-with-us-col {
    padding: 0px 30px;
    margin-top: 15%;
  }
}
