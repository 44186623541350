.how-we-do-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4% 15%;
  background-color: #6096b4;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .how-we-do-header {
    padding: 8% 40px;
  }
}

.text-center {
  font-family: Roboto;
  font-weight: 300;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0px;
}

@media screen and (max-width: 768px) {
  .text-center {
    font-size: 22px;
    line-height: 30px;
  }
}

.text-center strong {
  font-weight: 600;
}
