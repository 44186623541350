.about-us-details-section {
  background-color: var(--light-blue);
  background-image: url("../../../../assets/pattern_our_services.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: auto 100%;
  padding: 3% 4% 0% 4%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.details-item-container {
  margin: 2% 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
}
.details-item-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 40px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  flex: 1;
  text-align: center;
}
.details-item-title {
  font-size: 34px;
  line-height: 40px;
  color: var(--font-primary);
  margin-top: 22px;
}

.details-item-description {
  font-size: 22px;
  line-height: 32px;
  color: var(--font-secondary);
  margin-top: 20px;
}

.second-row {
  width: 30%;
}

@media screen and (max-width: 768px) {
  .details-item-container {
    width: 100%;
  }
  .details-item-title {
    width: 100%;
  }
  .details-item-description {
    width: 100%;
  }
  .second-row-item {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .details-item-container {
    width: 100%;
  }
  .details-item-title {
    width: 100%;
  }
  .details-item-description {
    width: 100%;
  }
  .second-row-item {
    width: 100%;
  }
}
