.our-work-section {
  background: #f7f9fa;
  background-color: var(--white);
  background: url("../../../../assets/bg_icon_2.svg") no-repeat left;
  background-size: auto 80%;
}

.our-work-section-text {
  font-family: Roboto;
  padding: 0px 187px 85px 187px;
  font-size: 22px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  color: #4b4b4b;
  cursor: default;
}

@media screen and (max-width: 768px) {
  .our-work-section-text {
    padding: 0px 20px;
  }
}
