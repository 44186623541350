.master {
  margin-top: 40px;
}

@media screen and (max-width: 768px) {
  .master {
    height: auto;
  }
}
.domain {
  transition: transform 1s;
}

.master-icon-hover {
  opacity: 0.8 !important;
  transform: scale(1.2) !important;
}
