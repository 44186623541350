.technological-solutions-section {
  background-color: #f7f9fa;
  background-image: url(../../../../assets/HowWeDoIt/pattern_about.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

.technological-solutions-text {
  padding: 0px 187px 85px 187px;
  font-family: Roboto;
  font-size: 22px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  color: #3b3a3b;
  cursor: default;
}

@media screen and (max-width: 768px) {
  .technological-solutions-text {
    padding: 0px 20px;
    margin-top: 15%;
  }
}
