.new-opening-section {
  background-color: #f7f9fa;
  padding: 0 10% 5% 10%;
}
.jobs-description {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  color: var(--font-primary);
}
.jobs-items-container {
  display: flex;
  flex-direction: column;
  padding: 2% 0;
}
@media screen and (max-width: 700px) {
  .jobs-description {
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
  }
}
